// // routes
import { PATH_REPORT } from '../../../../../../routes/VI/MKT/paths';
import { PATH_S_RANKING_LEADER } from '../../../../../../routes/generalPaths';
// // components
import SvgIconStyle from '../../../../../../components/SvgIconStyle';
// // ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  analytics: getIcon('ic_analytics'),
  search: getIcon('ic_search'),
  target: getIcon('ic_target'),
  question: getIcon('ic_question'),
  form: getIcon('ic_form'),
  export: getIcon('ic_analytics_export'),
};

// eslint-disable-next-line
export const navConfig = [
  {
    group: 'Indicadores',
    items: [
      {
        title: 'Emails Marketing',
        path: PATH_REPORT.bases,
        icon: ICONS.analytics
      },
      {
        title: 'Eventos',
        path: PATH_REPORT.eventos,
        icon: ICONS.analytics
      },
      {
        title: 'SDR Leads',
        path: PATH_REPORT.sdr,
        icon: ICONS.analytics
      }
    ]
  },

  {
    group: 'Rankings',
    path: PATH_S_RANKING_LEADER.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Super Ranking Líderes',
        path: PATH_S_RANKING_LEADER.general,
        icon: ICONS.analytics
      }
    ]
  },
];
