import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_OKR, PATH_TOOLS, PATH_EXPORT, PATH_S_RANKING, PATH_REPORT, PATH_GU, PATH_SETTINGS, PATH_PIPE, ATIVOMETRO, PATH_DASHBOARD, PATH_NPS, PATH_OFF_SHORE, INTERNAL_TRANSFERS, PATH_PRODUCTS, PATH_RKYC, PATH_FILES_MANAGEMENT, PATH_API_XP, PATH_TRAINING, PATH_ROA, PATH_COMPROMISSADAS, PATH_CA } from '../../../routes/generalPaths';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// ----------------------------------------------------------------------

// eslint-disable-next-line
const defaultNavConfig = ({ currentNavConfig, username, userRole, powerBiExport, isAssess, isAdmin, uploadEnabled, userCore = '', userBranch = '', userDepartment = '', userRoutes = [], user }) => {
  const pipeAdvisor = ((userRole === 'ADVISOR' || userRole === 'FARMER') && (userDepartment === 'ASSES' || userDepartment === 'DIGI')) || userRole === 'DEV';
  const pipeLeader = ((userRole === 'LEADER' || userRole === 'FLEADER') && (userDepartment === 'ASSES' || userDepartment === 'DIGI')) || userRole === 'DEV';
  const pipeManager = (userRole === 'MANAGER' && (userDepartment === 'ASSES' || userDepartment === 'DIGI')) || userRole === 'DEV' || userRoutes?.includes('pipemanager');
  const pipeHead = (userRole === 'HEAD' && (userDepartment === 'ASSES' || userDepartment === 'MELCONT')) || userRole === 'DEV';
  const assesManager = userDepartment === 'ASSES' && userRole === 'MANAGER';
  const assesHead = userDepartment === 'ASSES' && userRole === 'HEAD';
  return [

    ...(userDepartment === 'DADOS' || userDepartment === 'ASSES' || userDepartment === 'DIGI' || userRoutes?.includes('rkyc')) ? [
      {
        group: 'Ferramentas',
        subheader: 'RKYC',
        icon: <Iconify icon='solar:user-id-bold' />,
        items: [
          {
            title: 'Fichas',
            path: PATH_RKYC.list,
          },
          {
            title: 'Novo Cadastro',
            path: PATH_RKYC.new,
          }
        ],
      }
    ] : [],


    ...(['RV', 'ASSES', 'DIGI', 'DADOS', 'PROD', 'LUMI', 'BACKOFF', 'PJ'].includes(userDepartment) ||
      user?.attributes?.['custom:compromissad_enabled'] === 'TRUE') ? [
      {
        subheader: 'Compromissadas',
        group: 'Ferramentas',
        path: PATH_COMPROMISSADAS.root,
        icon: <Iconify icon={'fa6-solid:file-contract'} />,
        items: [
          {
            title: 'Nova Regra',
            path: PATH_COMPROMISSADAS.newRule,
          },
          {
            title: 'Minhas Regras',
            path: PATH_COMPROMISSADAS.list,
          },
          {
            title: 'Minhas Ordens',
            path: PATH_COMPROMISSADAS.listOrders,
          }
        ],
      }
    ] : [],

    // CEV
    ...userRoutes?.includes('rankingsxp') ? [
      {
        group: 'Relatórios CEV',
        items: [
          {
            icon: getIcon('ic_analytics_export'),
            title: 'Rankings XP',
            path: PATH_REPORT.rankingsXP,
          },
          {
            icon: getIcon('ic_analytics_export'),
            title: 'Wealth VGR',
            path: PATH_REPORT.wealthVGR,
          },
          {
            title: 'SDR Leads',
            path: PATH_REPORT.sdrleads,
            icon: getIcon('ic_analytics'),
          },
        ],
      }
    ] : [],

    ...(['RV', 'ASSES', 'DIGI', 'DADOS', 'PROD', 'LUMI', 'BACKOFF', 'SEG', 'PREV', 'VCS', 'PJ', 'SIMPLES'].includes(userDepartment) ||
      user?.attributes?.['custom:products_enabled'] === 'TRUE') ?
      [
        {
          subheader: 'Central PF',
          group: 'Produtos',
          icon: <Iconify icon={'ic:round-person'} />,
          items: [
            {
              title: 'Renda Variável',
              path: PATH_PRODUCTS.rv,
            },
            {
              title: 'Renda Fixa',
              path: PATH_PRODUCTS.rf,
            },
            {
              title: 'Fundos',
              path: PATH_PRODUCTS.fundos,
            },
            {
              title: 'Fundos Imobiliários',
              path: PATH_PRODUCTS.fiis,
            },
            {
              title: 'Previdência',
              path: PATH_PRODUCTS.prev,
            },
            {
              title: 'Internacionais',
              path: PATH_PRODUCTS.internacionais,
            },
            {
              title: 'Lumi Research',
              path: PATH_PRODUCTS.lumi,
            },
            {
              title: 'Alternativos',
              path: PATH_PRODUCTS.alternativos,
            },
            {
              title: 'Alocação',
              path: PATH_PRODUCTS.alocacao,
            }
          ]
        },
        {
          group: 'Produtos',
          items: [
            {
              title: 'Câmbio',
              path: PATH_PRODUCTS.cambio,
              icon: <Iconify icon='mingcute:exchange-dollar-line' />,
            },
            {
              title: 'Aquisição de Bens',
              path: PATH_PRODUCTS.aquisicao,
              icon: <Iconify icon='carbon:purchase' />,
            }
          ],
        },
        {
          group: 'Produtos',
          items: [
            {
              title: 'Ofertas',
              path: PATH_PRODUCTS.offers,
              icon: <Iconify icon={'ic:baseline-local-offer'} />,
            }
          ],
        },
        {
          group: 'Produtos',
          subheader: 'Conteúdos',
          icon: <Iconify icon={'fluent:content-view-28-regular'} />,
          items: [
            {
              title: 'Listar',
              path: PATH_PRODUCTS.contents,
            },
            ...(userDepartment === 'DADOS' || user?.attributes?.['custom:products_enabled'] === 'TRUE' || userRoutes?.includes('productsCrud')) ? [
              {
                title: 'Novo',
                path: PATH_PRODUCTS.new
              }
            ] : []
          ],
        },
        ...(userDepartment === 'DADOS' || userRoutes?.includes('acessosalocacao')) ? [
          {
            group: 'Produtos',
            items: [
              {
                title: 'Acessos',
                path: PATH_PRODUCTS.acessos,
                icon: getIcon('ic_analytics_export'),
              },
            ],
          }
        ] : []
      ] : [],

    ...userDepartment === 'RV' ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'Painel Brokers',
            path: PATH_REPORT.brokers,
            icon: getIcon('ic_analytics_export')
          }
        ],
      }
    ] : [],

    ...userRoutes?.includes('aderenciacarteira') ? [
      {
        // subheader: 'Produtos',
        group: 'Indicadores',
        items: [
          {
            title: 'Aderência Carteira',
            path: PATH_REPORT.produtos.aderencia,
            icon: getIcon('ic_analytics_export')
          },
        ]
      },
    ] : [],

    ...userDepartment === 'RV' || userRoutes?.includes('trocaativos') ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'Troca Ativos',
            path: PATH_REPORT.trocaAtivos,
            icon: getIcon('ic_analytics_export')
          }
        ],
      }
    ] : [],

    // ...userRoutes?.includes('relatorion2') ? [
    //   {
    //     group: 'Indicadores',
    //     items: [
    //       {
    //         title: 'Novo N3',
    //         path: PATH_REPORT.newN3,
    //         icon: getIcon('ic_analytics_export')
    //       }
    //     ],
    //   }
    // ] : [],

    ...userDepartment === 'ASSES' || userDepartment === 'DIGI' || (userDepartment === 'RV' && (userRole === 'EXPERT' || userRole === 'LEADER')) ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'Fee Fixo',
            path: PATH_REPORT.feeFixo,
            icon: getIcon('ic_analytics_export')
          }
        ],
      }
    ] : [],

    ...((userDepartment === 'ASSES' || userDepartment === 'DIGI') && (userRole === 'ADVISOR' || userRole === 'LEADER' || userRole === 'FARMER' || userRole === 'FLEADER'))
      || (userDepartment === 'RV' && userRole === 'EXPERT') ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'Financial Planning',
            path: PATH_REPORT.fp,
            icon: getIcon('ic_analytics_export')
          }
        ],
      }
    ] : [],

    // VCS
    ...(userRoutes?.includes('gerencialvcs')) ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'Gerencial',
            path: PATH_REPORT.gerencialvcs,
            icon: getIcon('ic_analytics_export')
          }
        ],
      }
    ] : [],

    ...(userRole === 'MANAGER' || userRoutes?.includes('okrgu')) ?
      [{
        // subheader: 'OKR',
        group: 'Campanhas',
        path: PATH_OKR.root,
        icon: getIcon('ic_target'),
        items: [
          {
            title: 'OKR',
            path: PATH_OKR.followUp.gu,
            icon: getIcon('ic_target'),
            // children: [
            // eslint-disable-next-line
            //   ...okrGeneral ? [{ title: 'Geral', path: PATH_OKR.followUp.general }]
            //     : userRole === 'MANAGER' ? [{ title: 'Unidade', path: PATH_OKR.followUp.gu }] : [],
            //   ...(isAssess && ((userRole === "ADVISOR") || (userRole === 'DEV') || (userRole === "LEADER"))) ? [{ title: 'Individual', path: PATH_OKR.followUp.advisor }] : [],
            //   ...(isAssess && ((userRole === "LEADER") || (userRole === 'DEV'))) ? [{ title: 'Equipe', path: PATH_OKR.followUp.leader }] : [],
            // ],
          },
          // {
          //   title: 'Sobre',
          //   path: PATH_OKR.about,
          //   icon: getIcon('ic_question'),
          // },
        ],
      }] : [],

    ...(userDepartment === 'ASSES' || userDepartment === 'DIGI' || userRoutes?.includes('gerencialoffshoreaais')) ?
      [
        {
          subheader: 'Off Shore',
          group: 'Indicadores',
          path: PATH_OFF_SHORE.root,
          icon: getIcon('ic_analytics_export'),
          items: [
            {
              title: 'Gerencial',
              path: PATH_OFF_SHORE.gerencialAAI,
              icon: getIcon('ic_analytics_export')
            },
            ...userRoutes?.includes('workingoffshore') ? [
              {
                title: 'Working',
                path: PATH_OFF_SHORE.working,
                icon: getIcon('ic_analytics_export')
              }
            ] : []
          ]
        }
      ] : [],

    ...(userCore === 'VI' || userCore === 'VCS') ?
      [{
        subheader: "Ativômetro PJ",
        group: 'Campanhas',
        path: ATIVOMETRO.root,
        icon: getIcon('ic_analytics'),
        items: [
          {
            title: 'Ranking',
            path: ATIVOMETRO.rankingPJ,
            icon: getIcon('ic_analytics'),
          },
          ...((userCore === 'VI' && userRole === 'ADVISOR') || ((userDepartment === 'ASSES' || userDepartment === 'SEG') && userRole === 'HEAD')) ?
            [{
              title: 'Individual',
              path: ATIVOMETRO.aaiPJ,
              icon: getIcon('ic_analytics'),
            }] : [],
        ],
      }] : [],

    {
      subheader: 'Super Ranking AAI',
      group: 'Rankings',
      path: PATH_S_RANKING.root,
      icon: getIcon('ic_analytics_export'),
      items: [
        {
          title: 'Geral',
          path: PATH_S_RANKING.general,
          icon: getIcon('ic_analytics_export')
        },
        ...isAssess && userRole !== 'MANAGER' ? [{
          title: 'Individual',
          path: PATH_S_RANKING.individual,
          icon: getIcon('ic_analytics_export')
        }] : []
      ]
    },

    ...['ASSES', 'DIGI', 'RV', 'PROD', 'LUMI', 'DADOS'].includes(userDepartment) || userCore === 'VGR' || user?.attributes?.['custom:vgr_admin_enabled'] === 'TRUE' ? [
      {
        group: 'Gestora',
        icon: getIcon('ic_analytics_export'),
        items: [
          {
            title: 'Carteira Administrada',
            path: PATH_CA.consolidada,
            icon: <Iconify icon='ion:wallet-outline' />
          },
        ]
      }
    ] : [],

    // PIPE
    ...pipeAdvisor || pipeLeader || pipeManager || pipeHead ?
      [{
        subheader: 'Pipe',
        group: 'Ferramentas',
        path: PATH_PIPE.root,
        icon: getIcon('ic_analytics'),
        items: [
          ...pipeAdvisor || pipeLeader || pipeManager || (userDepartment === 'MELCONT' && userRole === 'HEAD') ? [
            {
              title: 'Assessor',
              path: PATH_PIPE.pipeAdvisor,
              icon: getIcon('ic_analytics'),
            }
          ] : [],
          ...pipeLeader ? [
            {
              title: 'Líder',
              path: PATH_PIPE.pipeLeader,
              icon: getIcon('ic_analytics'),
            }
          ] : [],
          ...pipeManager ? [
            {
              title: 'Gestor',
              path: PATH_PIPE.pipeManager,
              icon: getIcon('ic_analytics'),
            }
          ] : [],
          ...pipeHead ? [
            {
              title: 'Head',
              path: PATH_PIPE.pipeHead,
              icon: getIcon('ic_analytics'),
            }
          ] : [],
          {
            title: 'Acompanhamento',
            path: PATH_PIPE.fup,
            icon: getIcon('ic_analytics'),
          }
        ],
      }] : [],

    // GESTÃO A VISTA
    ...user?.attributes?.['custom:gestao_vista_enabled'] === 'TRUE' ? [
      {
        subheader: 'Gestão à Vista',
        group: 'Ferramentas',
        icon: getIcon('ic_kanban'),
        path: PATH_TOOLS.gv.root,
        items: [
          {
            title: 'Apresentação',
            icon: getIcon('ic_kanban'),
            path: PATH_TOOLS.gv.fullscreen
          },
          {
            title: 'Playlists',
            icon: getIcon('ic_form'),
            path: PATH_TOOLS.gv.playlists
          },
          {
            title: 'Monitoramento',
            icon: getIcon('ic_analytics'),
            path: PATH_TOOLS.gv.monitoring
          }
        ]
      }
    ] : [],

    // DESTAQUES ASSESSORIA
    ...(userRoutes?.includes('destaquesaais')) ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'Destaques Assessoria',
            path: PATH_REPORT.destaquesaais,
            icon: getIcon('ic_analytics'),
          },
        ]
      }
    ] : [],

    // PAINEL PRIVATE
    ...(userDepartment === 'DADOS' || userRoutes?.includes('painelprivate')) ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'Painel Private',
            path: PATH_REPORT.private,
            icon: getIcon('ic_analytics'),
          },
        ]
      }
    ] : [],

    // TRAININGS
    ...(userDepartment === 'DADOS' || userDepartment === 'MELCONT' || userRoutes?.includes('trainings')) ? [
      {
        subheader: 'Treinamentos',
        icon: <Iconify icon={'healthicons:i-training-class'} />,
        group: 'Ferramentas',
        items: [
          {
            title: 'Treinamentos',
            path: PATH_TRAINING.list
          },
          {
            title: 'Módulos',
            path: PATH_TRAINING.modules
          },
          {
            title: 'Turmas',
            path: PATH_TRAINING.classes
          },
          {
            title: 'Officers',
            path: PATH_TRAINING.officer
          },
          {
            title: 'Reuniões',
            path: PATH_TRAINING.meets
          }
        ],
      }
    ] : [],

    // INTERNAL TRANSFERS
    ...(userDepartment === 'DADOS' || userRoutes?.includes('internaltransfers')) ?
      [{
        group: 'Ferramentas',
        subheader: 'Transferências',
        icon: getIcon('ic_form'),
        items: [
          {
            title: 'Solicitar',
            path: INTERNAL_TRANSFERS.request,
            // icon: getIcon('ic_analytics'),
          },
          {
            title: 'Acompanhar',
            path: INTERNAL_TRANSFERS.follow,
            // icon: getIcon('ic_analytics'),
          }
        ],
      }] : [],

    // METAS MASTER
    ...(userDepartment === 'ASSES' && userRole === 'MANAGER') || userRoutes?.includes('metasmaster') ?
      [{
        group: 'Bônus',
        items: [
          {
            title: 'Metas Master',
            path: PATH_REPORT.metas.master,
            icon: getIcon('ic_analytics'),
          }
        ],
      }] : [],

    // BASE
    // ----------------------------------------------------------------------
    ...(userDepartment === 'ASSES' || userDepartment === 'DIGI' || (userDepartment === 'RV' && userRole === 'EXPERT') || userRoutes?.includes('specialDashboard')) ? [
      {
        // subheader: 'Home',
        group: 'Home',
        items: [
          {
            title: 'Dashboard',
            path: PATH_DASHBOARD.base,
            icon: getIcon('ic_dashboard'),
          },
          {
            title: 'Campanhas',
            path: PATH_DASHBOARD.campaigns,
            icon: getIcon('ic_speaker')
          },
        ],
      }
    ] : [],

    // MESA BANKING
    ...userDepartment === 'BANKING' || userRoutes?.includes('mesabanking') ? [
      {
        group: 'Home',
        items: [
          {
            icon: getIcon('ic_analytics_export'),
            title: 'Mesa Banking',
            path: PATH_REPORT.mesaBanking,
          }
        ],
      }
    ] : [],

    ...userDepartment === 'PREV' || (userDepartment === 'SEG' && userRole === 'HEAD') ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'Previdência XP',
            path: PATH_REPORT.prevXP,
            icon: getIcon('ic_analytics'),
          },
        ],
      }
    ] : [],

    ...(userDepartment === 'ASSES' || userDepartment === 'DIGI' || userRoutes?.includes('npsmedalliaaais')) ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'NPS Medallia',
            path: PATH_NPS.medalliaAAI,
            icon: getIcon('ic_analytics'),
          },
        ],
      }
    ] : [],

    ...userRoutes?.includes('creditoxp') ?
      [
        {
          group: 'Indicadores',
          items: [
            {
              title: 'Crédito XP',
              path: PATH_REPORT.creditoXP,
              icon: getIcon('ic_analytics'),
            },
          ],
        }
      ] : [],

    ...assesManager || userRoutes?.includes('eventosmkt') ?
      [
        {
          group: 'Indicadores',
          items: [
            {
              title: 'MKT Eventos',
              path: PATH_REPORT.eventos,
              icon: getIcon('ic_analytics'),
            },
          ],
        }
      ] : [],

    // COMPROMISSADAS
    ...((userDepartment === 'ASSES' || userDepartment === 'DIGI') && (userRole === 'ADVISOR' || userRole === 'LEADER' || userRole === 'FARMER'))
      || userRoutes?.includes('compromissadas') ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'Compromissadas',
            path: PATH_REPORT.compromissadas.root,
            icon: getIcon('ic_analytics'),
          },
        ],
      }
    ] : [],

    ...(((userDepartment === 'ASSES' || userDepartment === 'DIGI') && (userRole === 'MANAGER' || userRole === 'HEAD'))
      || userRoutes?.includes('compromissadasgu')) ? [
      {
        group: 'Indicadores',
        items: [
          {
            title: 'Compromissadas',
            path: PATH_REPORT.compromissadas.gu,
            icon: getIcon('ic_analytics'),
          },
        ],
      }
    ] : [],
    // ...userCore === 'VI' ? [{
    //   subheader: 'Documentos',
    //   items: [
    //     {
    //       title: 'Lumi',
    //       path: `${PATH_TOOLS.sharing.list}#lumi`,
    //       icon: getIcon('ic_share'),
    //     },
    //     {
    //       title: 'Alocação',
    //       path: `${PATH_TOOLS.sharing.list}#alocacao`,
    //       icon: getIcon('ic_share'),
    //     },
    //   ],
    // }] : [],

    // Rankinkg Gerais
    ...(assesManager) || userRoutes?.includes('rankingsgeraisgu') ? [
      {
        group: 'Rankings',
        path: PATH_REPORT.rankings,
        icon: getIcon('ic_analytics'),
        items: [
          {
            title: 'Rankings Gerais',
            path: PATH_REPORT.rankings,
            icon: getIcon('ic_analytics'),
          },
        ],
      },
    ] : [],

    ...userRoutes?.includes('historicocliente') ? [
      {
        subheader: 'Marketing',
        group: 'Indicadores',
        path: PATH_REPORT.clientHistory,
        icon: getIcon('ic_analytics'),
        items: [
          {
            title: 'Histórico Cliente',
            path: PATH_REPORT.clientHistory,
            icon: getIcon('ic_analytics'),
          },
          {
            title: 'SDR Leads',
            path: PATH_REPORT.sdrleads,
            icon: getIcon('ic_analytics'),
          },
        ],
      },
    ] : [],

    ...userRoutes?.includes('paybackaai') ?
      [{
        group: 'Indicadores',
        items: [
          {
            title: 'Payback AAI',
            path: PATH_REPORT.payback,
            icon: getIcon('ic_analytics'),
          },
        ],
      }] : [],

    ...powerBiExport || userRoutes?.includes('powerBiExport') ? [{
      subheader: 'Bases Exportáveis',
      group: 'Indicadores',
      path: PATH_EXPORT.root,
      icon: getIcon('ic_analytics_export'),
      items: [
        {
          title: 'AAI/Clientes',
          path: PATH_EXPORT.aai,
          icon: getIcon('ic_analytics_export'),
        },
        ...userRoutes?.includes('exportbasesclientes') ? [
          {
            title: 'Clientes (emails)',
            path: PATH_EXPORT.clientes,
            icon: getIcon('ic_analytics_export'),
          }
        ] : [],
        ...(currentNavConfig?.['Bases Exportáveis'] || [])
      ],
    }] : [],

    // ...userCore === 'VI' ? [{
    //   subheader: 'Campanhas',
    //   items: [
    //     {
    //       title: 'Ativ. 300k+ (Ago-Dez 22)',
    //       path: PATH_CAMPAIGNS.root,
    //       icon: getIcon('ic_analytics'),
    //       children: [
    //         { title: 'Ranking', path: PATH_CAMPAIGNS.ativacao300k.ranking },
    //         { title: 'Consulta', path: PATH_CAMPAIGNS.ativacao300k.consulta },
    //       ],
    //     },
    //   ],
    // }] : [],

    // USUÁRIOS
    ...userDepartment === 'RH' || (userDepartment === 'ASSES' && (userRole === 'MANAGER' || userRole === 'HEAD')) || userRoutes?.includes('users') || user?.attributes?.['custom:read_users_enabled'] === 'TRUE' ? [{
      group: 'Ferramentas',
      items: [
        {
          title: 'Usuários',
          path: PATH_SETTINGS.user.root,
          icon: getIcon('ic_user'),
          children: [
            { title: 'Usuários', path: PATH_SETTINGS.user.list },
            ...user?.attributes?.['custom:edit_user_enabled'] === 'TRUE' ? [{ title: 'Criar', path: PATH_SETTINGS.user.newUser }] : [],
            ...userDepartment === 'DADOS' ? [
              { title: 'Atualizações', path: PATH_SETTINGS.user.updates },
              { title: 'Logs de acesso', path: PATH_SETTINGS.user.cognito },
            ] : []
          ],
        },
      ]
    }] : [],

    // ROA
    ...userDepartment === 'DADOS' || userRoutes?.includes('analiseRoa') ? [
      {
        group: 'Ferramentas',
        items: [
          {
            title: 'Análise de ROA',
            path: PATH_ROA.root,
            icon: getIcon('ic_analytics'),
          },
        ]
      }] : [],

    // GU
    ...(assesManager) || (userRoutes?.includes('gerencialgu')) || assesHead ? [
      {
        subheader: 'Painéis GU',
        group: 'Indicadores',
        // path: PATH_GU.root,
        icon: getIcon('ic_analytics'),
        items: [
          ...(userDepartment !== 'ASSES') ? [{
            title: 'Fee Fixo',
            path: PATH_GU.feeFixo,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...(assesManager || userRoutes?.includes('basesgu')) ? [{
            title: 'Bases',
            path: PATH_GU.bases,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...(userRoutes?.includes('basesdigital')) ? [
            {
              title: 'Bases Digital',
              path: `${PATH_GU.root}/basesdigital`,
              icon: getIcon('ic_analytics'),
            }
          ] : [],
          ...(assesManager || userRoutes?.includes('booktatico')) ? [{
            title: 'Book Tático',
            path: PATH_GU.book,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...(assesManager || userRoutes?.includes('acessos')) ? [{
            title: 'Acessos',
            path: PATH_GU.acessos,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...(assesManager || userRoutes?.includes('campanhascrm')) ? [{
            title: 'Campanhas CRM',
            path: PATH_GU.campanhasCrm,
            icon: getIcon('ic_analytics'),
          }] : [],
          // {
          //   title: 'Ativ. 300k+ Asses',
          //   path: PATH_GU.ativAsses,
          //   icon: getIcon('ic_analytics'),
          // },
          // {
          //   title: 'Ativ. 300k+ Master',
          //   path: PATH_GU.ativMaster,
          //   icon: getIcon('ic_analytics'),
          // },
          {
            title: 'Gerencial',
            path: PATH_GU.gerencial,
            icon: getIcon('ic_analytics'),
          },
          ...(assesManager || userRoutes?.includes('oportunidadesativacaogu')) ? [{
            title: 'Oportunidades Ativ.',
            path: PATH_GU.oportunidades,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...(assesManager || userRoutes?.includes('previdenciagu')) ? [{
            title: 'Previdência',
            path: PATH_GU.previdencia,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...(assesManager || userRoutes?.includes('superrankinggu')) ? [{
            title: 'Super Ranking AAI',
            path: PATH_GU.superRanking,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...(assesManager || userRoutes?.includes('superrankinglideresgu')) ? [{
            title: 'Super Ranking Líderes',
            path: PATH_GU.superRankingLeader,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...(assesManager || userRoutes?.includes('indicadoresg20')) ? [{
            title: 'Indicadores G20',
            path: PATH_GU.indicadores,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...(assesManager || userRoutes?.includes('n2gu')) ? [{
            title: 'N2',
            path: PATH_GU.n2,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...(assesManager || userBranch === 'DIGITAL' || userRoutes?.includes('digitalgu')) ? [
            {
              title: 'Digital',
              path: PATH_GU.guDigi,
              icon: getIcon('ic_analytics'),
            }
          ] : [],
          ...(assesManager || userRoutes?.includes('basecambiogu')) ? [
            {
              title: 'Câmbio',
              path: PATH_GU.cambio,
              icon: getIcon('ic_analytics'),
            }
          ] : [],
          {
            title: 'ROAe',
            path: PATH_GU.receitaGestor,
            icon: getIcon('ic_analytics'),
          },
          // {
          //   title: 'Rankings XP',
          //   path: PATH_GU.rankingsXP,
          //   icon: getIcon('ic_analytics'),
          // },
          {
            title: 'Cross Sell',
            path: PATH_GU.xsell,
            icon: getIcon('ic_analytics'),
          },
        ]
      }] : [],

    ...assesManager || assesHead || userRoutes?.includes('metasgu') ? [
      {
        group: 'Bônus',
        items: [
          {
            title: 'Metas GU',
            path: PATH_GU.metas,
            icon: getIcon('ic_analytics'),
          }
        ]
      },
    ] : [],

    ...(userDepartment === 'ASSES' && (userRole === 'ADVISOR' || userRole === 'MANAGER' || userRole === 'LEADER')) ?
      [
        {
          subheader: 'Ciclo Avaliativo',
          group: 'Indicadores',
          icon: getIcon('ic_analytics'),
          items: [
            ...userRole === 'LEADER' ?
              [{
                title: 'Performance AAI',
                path: PATH_REPORT.performance.rating,
                icon: getIcon('ic_analytics'),
                children: [
                  {
                    title: 'Individual',
                    path: PATH_REPORT.performance.rating,
                  },
                  {
                    title: 'Equipe',
                    path: PATH_REPORT.performance.ratingLeader,
                  },
                ]
              },
              {
                title: 'Performance Líder',
                path: PATH_REPORT.performance.ratingLeaderSelf,
                icon: getIcon('ic_analytics'),
              }] : [],
            ...userRole === 'MANAGER' ?
              [{
                title: 'Performance AAI',
                path: PATH_REPORT.performance.rating,
                icon: getIcon('ic_analytics'),
              },
              {
                title: 'Performance Líder',
                path: PATH_REPORT.performance.ratingLeaderManager,
                icon: getIcon('ic_analytics'),
              }] : [],
            ...userRole === 'ADVISOR' ? [{
              title: 'Performance AAI',
              path: PATH_REPORT.performance.rating,
              icon: getIcon('ic_analytics'),
            }] : []
          ],
        }
      ] : [],

    ...(userDepartment === 'RH' || (userRoutes?.includes('performancesemestralaaiexport'))) ?
      [{
        subheader: 'Ciclo Avaliativo',
        group: 'Indicadores',
        // path: PATH_REPORT.performance.root,
        icon: getIcon('ic_analytics'),
        items: [
          {
            title: 'Performance AAI',
            path: PATH_REPORT.performance.rating,
            icon: getIcon('ic_analytics'),
          },
          {
            title: 'Performance Líder',
            path: PATH_REPORT.performance.ratingLeader,
            icon: getIcon('ic_analytics'),
          },
        ],
      }] : [],

    ...((userCore === 'VI' && userDepartment === 'PJ' && userRole === 'EXPERT') || userRoutes?.includes('gerencialpj') ||
      userRoutes?.includes('basespj') || (userDepartment === 'PROD' && userRole === 'MANAGER')) ? [{
        subheader: 'Performance',
        group: 'Indicadores',
        path: PATH_REPORT.performance.root,
        icon: getIcon('ic_analytics'),
        items: [
          ...userDepartment === 'PROD' && userRole === 'MANAGER' ? [{
            title: 'Corretagem',
            path: PATH_REPORT.performance.corretagem.advisor,
            icon: getIcon('ic_analytics'),
          }] : [],
          ...((userCore === 'VI' && userDepartment === 'PJ' && userRole === 'EXPERT') || userRoutes?.includes('gerencialpj')) ? [{
            title: 'Gerencial',
            path: userDepartment === 'PJ' ? PATH_REPORT.performance.gerencial : '/pj/performance/gerencial',
            icon: getIcon('ic_analytics'),
          }] : [],
          ...((userCore === 'VI' && userDepartment === 'PJ' && userRole === 'EXPERT') || userRoutes?.includes('basespj')) ? [{
            title: 'Bases',
            path: userDepartment === 'PJ' ? PATH_REPORT.performance.bases : '/pj/performance/bases',
            icon: getIcon('ic_analytics'),
          }] : [],
        ],
      }] : [],

    ...(userRoutes?.includes('roagestor')) ? [
      {
        group: 'Indicadores',
        items: [{
          title: 'ROAe',
          path: PATH_GU.receitaGestor,
          icon: getIcon('ic_analytics'),
        }],
      }
    ] : [],

    ...currentNavConfig,

    // ...(isAssess && (TEST_TRANSFER.includes(username) || isAdmin || userRole === 'MANAGER')) ? [{
    //   subheader: 'Solicitações',
    //   path: PATH_REQUESTS.root,
    //   group: 'Ferramentas',
    //   icon: getIcon('ic_form'),
    //   items: [
    //     {
    //       title: 'Transferência',
    //       path: PATH_REQUESTS.transfer.new,
    //       icon: getIcon('ic_form'),
    //       children: [
    //         {
    //           title: 'Solicitar',
    //           path: PATH_REQUESTS.transfer.new,
    //         },
    //         {
    //           title: 'Acompanhar',
    //           path: PATH_REQUESTS.transfer.followUp,
    //         },
    //         ...isAdmin ? [{
    //           title: 'Histórico',
    //           path: PATH_REQUESTS.transfer.history,
    //         }] : []
    //       ]
    //     },
    //   ],
    // }] : [],

    {
      group: 'Ferramentas',
      items: [
        {
          title: 'Uploads/Downloads',
          path: PATH_FILES_MANAGEMENT.filesManagement,
          icon: <Iconify icon='ant-design:file-sync-outlined' />
        },
        ...userDepartment === 'DADOS' || user?.attributes?.['custom:api_xp_enabled'] === 'TRUE' ? [
          {
            title: 'API XP',
            path: PATH_API_XP.apiXP,
            icon: <Iconify icon='arcticons:xp-investimentos' />
          }
        ] : []
      ],
    },

    ...uploadEnabled ? [{
      subheader: 'Inputs',
      path: PATH_TOOLS.upload.root,
      icon: getIcon('ic_upload'),
      group: 'Ferramentas',
      items: [
        {
          title: 'Envios',
          path: PATH_TOOLS.upload.list,
          icon: getIcon('ic_upload'),
        },
      ],
    }] : [],

    // {
    //   subheader: 'Ferramentas',
    //   items: [
    //     {
    //       title: 'Buscar cliente',
    //       path: PATH_TOOLS.find,
    //       icon: getIcon('ic_search'),
    //     },
    //   ],
    // }
  ]
}

export default defaultNavConfig;