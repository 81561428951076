// routes
import { PATH_SETTINGS, PATH_S_RANKING_LEADER } from '../../../../../../routes/generalPaths';
// components
import SvgIconStyle from '../../../../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  form: getIcon('ic_form'),
  upload: getIcon('ic_upload'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  maintenance: getIcon('ic_maintenance'),
  search: getIcon('ic_search'),
  export: getIcon('ic_analytics_export'),
};

// eslint-disable-next-line
export const navConfig = [
  {
    group: 'Ferramentas',
    items: [
      {
        title: 'Usuários',
        path: PATH_SETTINGS.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Criar', path: PATH_SETTINGS.user.newUser },
          { title: 'Usuários', path: PATH_SETTINGS.user.list },
        ],
      },
    ]
  },

  {
    group: 'Rankings',
    path: PATH_S_RANKING_LEADER.root,
    icon: ICONS.analytics,
    items: [
      {
        title: 'Super Ranking Líderes',
        path: PATH_S_RANKING_LEADER.general,
        icon: ICONS.analytics
      }
    ]
  },
];
