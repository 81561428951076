import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/settings')} />}>
      <Component {...props} />
    </Suspense>
  );
};

// ----------------------------------------------------------------------

export function Router() {
  const { user, username, userRole, userDepartment, userBranch, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userBranch, userRoutes }),

    {
      path: 'settings',
      element: <DashboardLayout />,
      children: [
        {
          path: 'user',
          children: [
            { element: <Navigate to="/settings/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            ...user?.attributes?.['custom:edit_user_enabled'] === 'TRUE' ? [{ path: 'new', element: <UserCreate /> }] : [],
            { path: ':username/edit', element: <UserCreate /> },
          ],
        },
      ],
    },

    {
      path: 'superranking/leader',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/superranking/leader/general" replace />, index: true },
        { path: 'general', element: <SuperRankingLeaderGeneral /> },
      ]
    },

    ...(userRoutes?.includes('destaquesaais')) ? [
      {
        path: 'report/destaquesaais',
        element: <DashboardLayout />,
        children: [
          { element: <DestaquesAAIs />, index: true },
        ]
      }
    ] : [],

    ...user?.attributes?.['custom:announc_enabled'] === 'TRUE' ? [{
      path: 'announcements',
      element: <DashboardLayout />,
      children: [
        { path: 'new', element: <NewAnnouncement /> },
        { path: ':id/edit', element: <NewAnnouncement isEdit /> },
        { path: 'management', element: <AnnouncementsManagement /> },
      ]
    }] : [],
  ]);
}

// SUPER RANKING
const SuperRankingLeaderGeneral = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/SuperRankingLeaderGeneral')));

// SETTINGS
const UserList = Loadable(lazy(() => import('../../../../pages/VI/general/user/UserList')));
const UserCreate = Loadable(lazy(() => import('../../../../pages/VI/general/user/UserCreate')));
const DestaquesAAIs = Loadable(lazy(() => import('../../../../pages/VI/general/power-bi/DestaquesAAIs')));

// ANNOUNCEMENTS
const NewAnnouncement = Loadable(lazy(() => import('../../../../pages/general/announcements/NewAnnouncement')));
const AnnouncementsManagement = Loadable(lazy(() => import('../../../../pages/general/announcements/Management')));
