// routes
import { PATH_TEST, PATH_SETTINGS, PATH_REPORT } from '../../../../../routes/VI/DADOS/paths';
// components
import SvgIconStyle from '../../../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  form: getIcon('ic_form'),
  upload: getIcon('ic_upload'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  maintenance: getIcon('ic_maintenance'),
  search: getIcon('ic_search'),
  speaker: getIcon('ic_speaker'),
  target: getIcon('ic_target'),
  question: getIcon('ic_question'),
  share: getIcon('ic_share'),
  export: getIcon('ic_analytics_export'),
};

// eslint-disable-next-line
export const navConfig = [

  // TEST
  // ----------------------------------------------------------------------
  {
    // subheader: 'Testes',
    group: 'Ferramentas',
    items: [
      {
        title: 'Teste',
        icon: ICONS.maintenance,
        children: [
          {
            title: 'BI',
            path: PATH_TEST.bi,
            icon: ICONS.analytics,
            children: [
              {
                title: 'Power BI',
                path: PATH_TEST.bi,
              },
              {
                title: 'Composite Model',
                path: PATH_TEST.composite,
                icon: ICONS.maintenance
              }
            ]
          }, 
          {
            title: 'Análise de ROA',
            path: PATH_TEST.roa,
            icon: ICONS.analytics
          },
          {
            title: 'Calculadora NPS',
            path: PATH_TEST.npscalculator,
            icon: ICONS.analytics
          },
          {
            title: 'Campanhas Ativas',
            path: PATH_TEST.campaigns,
            icon: ICONS.analytics
          }
        ]
      }
    ],
  },    
  {
    group: 'Indicadores',
    items: [
      {
        title: 'Emails Marketing',
        path: PATH_REPORT.bases,
        icon: ICONS.analytics
      },
      {
        title: 'SDR Leads',
        path: PATH_REPORT.sdrleads,
        icon: ICONS.analytics
      }
    ]
  },
  {
    group: 'Ferramentas',
    items: [
      {
        title: 'Acessos',
        path: '/acessos',
        icon: getIcon('ic_analytics')
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Configurações',
    group: 'Ferramentas',
    path: PATH_SETTINGS.root,
    icon: ICONS.maintenance,
    items: [
      // MANAGEMENT : User
      // {
      //   title: 'Usuários',
      //   path: PATH_SETTINGS.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'Criar', path: PATH_SETTINGS.user.newUser },
      //     { title: 'Usuários', path: PATH_SETTINGS.user.list },
      //     { title: 'Atualizações', path: PATH_SETTINGS.user.updates },
      //     { title: 'Logs de acesso', path: PATH_SETTINGS.user.cognito },
      //   ],
      // },

      // MANAGEMENT : Relatórios
      {
        title: 'Power Bi',
        path: PATH_SETTINGS.report.root,
        icon: ICONS.maintenance
      },

      // MANAGEMENT : Campanhas
      {
        title: 'Campanhas',
        path: PATH_SETTINGS.campaign.root,
        icon: ICONS.maintenance
      },

      // MANAGEMENT : Profiles
      {
        title: 'Assessores',
        path: PATH_SETTINGS.profiles,
        icon: ICONS.maintenance
      },
    ],
  },
];
